import { render, staticRenderFns } from "./touchPaint.vue?vue&type=template&id=625e396e&scoped=true"
import script from "./touchPaint.vue?vue&type=script&lang=js"
export * from "./touchPaint.vue?vue&type=script&lang=js"
import style0 from "./touchPaint.vue?vue&type=style&index=0&id=625e396e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "625e396e",
  null
  
)

export default component.exports