const ongoingTouches = [];
let el;
let ctx;
let density = 1;
let selectedColor = 0;
let selectedBrush = 0;

const optionsData = {
    colorsList: [
        {red: 10, green: 100, blue: 200, id: 0}, 
        {red: 10, green: 200, blue: 20, id: 1}, 
        {red: 255, green: 5, blue: 5, id: 2},
        {red: 255, green: 250, blue: 5, id: 3},
        {red: 255, green: 140, blue: 5, id: 4}, 
        {red: 71, green: 45, blue: 6, id: 5},
        {red: 255, green: 255, blue: 255, id: 6},
        {red: 0, green: 0, blue: 0, id:7},
    ],
    brushes: [
        {width: 2, id: 0},
        {width: 5, id: 1},
        {width: 10, id: 2},
        {width: 25, id: 3},
    ]
}

function setOption(data) {
    if (data.newBrush !== undefined) selectedBrush = data.newBrush;
    if (data.newColor !== undefined) selectedColor = data.newColor;
    if (data.clear) clearPage();
}

function clearPage() {
    ctx.fillStyle = "rgb(255,255,255)";
    ctx.fillRect(0, 0, el.width, el.height);
}

function startup() {
    el = document.getElementById('canvas');
    el.addEventListener('touchstart', handleStart);
    el.addEventListener('touchend', handleEnd);
    el.addEventListener('touchcancel', handleCancel);
    el.addEventListener('touchmove', handleMove);

    ctx = el.getContext('2d');
    clearPage();
}

function cleanUp() {
    el.removeEventListener('touchstart', handleStart);
    el.removeEventListener('touchend', handleEnd);
    el.removeEventListener('touchcancel', handleCancel);
    el.removeEventListener('touchmove', handleMove);

}


function getCoords(evt, touch) {
    return {
        x: touch.pageX - evt.srcElement.offsetLeft,
        y: touch.pageY - evt.srcElement.offsetTop,
    }
}

document.addEventListener("DOMContentLoaded", startup);

function getDistance(x1,x2,y1,y2) {
    let xDif = x1 - x2;
    let yDif = y1 - y2;
    return Math.sqrt((xDif *xDif) + (yDif * yDif));
}

function handleStart(evt) {
    evt.preventDefault();
    const touches = evt.changedTouches;

    for (let i = 0; i < touches.length; i++) {
        const coords = getCoords(evt, touches[i])
        ongoingTouches.push(copyTouch(touches[i]));
        ctx.beginPath();
        ctx.arc(coords.x, coords.y, getBrush(), 0, 2 * Math.PI, false);  // a circle at the start
        ctx.fillStyle = getColor(selectedColor);
        ctx.fill();
    }
}

function handleMove(evt) {
    evt.preventDefault();
    const touches = evt.changedTouches;

    for (let i = 0; i < touches.length; i++) {
        const idx = ongoingTouchIndexById(touches[i].identifier);

        if (idx < 0) {
            continue;
        }
        const coords = getCoords(evt, touches[i])
        const ongoingCoords = getCoords(evt, ongoingTouches[idx])
        let travelDistance = getDistance(ongoingTouches[idx].pageX, touches[i].pageX, ongoingTouches[idx].pageY, touches[i].pageY) // get distance between current and previous touch points
        let drawPointCount = travelDistance / density; // Gets the number of circles to draw between touch points
        let xTravelMove = (coords.x - ongoingCoords.x) / drawPointCount; // Get x distance between each draw point
        let yTravelMove = (coords.y - ongoingCoords.y) / drawPointCount; // Get y distance between each draw point

        let tempX;
        let tempY;
        for (let j = 0; j < drawPointCount; j++) { // Loops for each draw point between touch points
            tempX = ongoingCoords.x + (j * xTravelMove);
            tempY = ongoingCoords.y + (j * yTravelMove)
            ctx.beginPath();
            ctx.arc(tempX, tempY, getBrush(), 0, 2 * Math.PI, false)
            ctx.fillStyle = getColor(selectedColor)
            ctx.fill();
         
        }
        ongoingTouches.splice(idx, 1, copyTouch(touches[i]));  // swap in the new touch record
        
    }
}

function handleEnd(evt) {
    evt.preventDefault();
    const touches = evt.changedTouches;

    for (let i = 0; i < touches.length; i++) {
        let idx = ongoingTouchIndexById(touches[i].identifier);
        
        if (idx < 0) {
            continue;
        }
        ongoingTouches.splice(idx, 1);  // remove it; we're done
        
    }
}

function handleCancel(evt) {
    evt.preventDefault();
    const touches = evt.changedTouches;

    for (let i = 0; i < touches.length; i++) {
        let idx = ongoingTouchIndexById(touches[i].identifier);
        ongoingTouches.splice(idx, 1);  // remove it; we're done
    }
}

function getColor(colorId) {
    const color = optionsData.colorsList[colorId];
    return `rgb(${color.red},${color.green},${color.blue})`;
}

function getBrush() {
    return optionsData.brushes[selectedBrush].width;
}

function copyTouch({ identifier, pageX, pageY }) {
    return { identifier, pageX, pageY };
}

function ongoingTouchIndexById(idToFind) {
    for (let i = 0; i < ongoingTouches.length; i++) {
        const id = ongoingTouches[i].identifier;

        if (id == idToFind) {
            return i;
        }
    }
    return -1;    // not found
}

export default startup;
export {cleanUp, setOption, optionsData};
