<template>
<div>
    <ProjectPageTemplate
        projectTitle="TOUCH PAINT"
        :imageSource="require('@/assets/images/projectLinks/touchPaintLink2.png')"
        shortDescription="SUITABLE FOR TOUCH SCREEN - Press on the canvas to draw. Select a colour from the picker 
        and line width using the options. The cancel icon will clear the canvas."
        longDescription= "Coded in JavaScript, using the MDN Web Docs on touch actions as a base. Added a full line drawing between points, line width and colour selection options. I used this project to gain an understanding of touch controls and the processes to use them as it was not something I had previous used."
        :importedLinkData="techLinksData"
    >
    <template v-slot:main-content>
        <div class='content-container'>
            <touchPaint></touchPaint>
        </div>
        <div class="rotate-screen">
            <UnableToDisplay
                :imageSource="require('@/assets/images/rotate.svg')"
                text="ROTATE SCREEN"
            />
        </div>
    </template>
    </ProjectPageTemplate>
</div>

</template>

<script>
import ProjectPageTemplate from "@/components/ProjectPageTemplate";
import touchPaint from '../components/touchPaint.vue';
import UnableToDisplay from "@/components/page_elements/UnableToDisplay.vue"
import techLinks from '@/data/techLinks.js'
export default {
    name: 'jsButtons',
    components: {
        ProjectPageTemplate,
        touchPaint,
        UnableToDisplay,
    },
    data() {
        return {
            techLinksData: [
                techLinks.javascript,
            ],
        }
  }
}
</script>

<style lang="scss" scoped>

@media screen and (orientation:portrait)  {
    @media screen and (max-width: 670px) {
        .content-container {
            display: none;
        }
        .rotate-screen.rotate-screen {
            display: flex;
        }
    }
}

.rotate-screen {
    display: none;
    width: 100%;
}
</style>