<template>
    <div id="touch-container">
        <canvas id="canvas" width="420" height="375"></canvas>
        <div class="paint-options">
            <div class="display-options">
                <div class="selected-color-container">
                    <div class="selected-color" :style="`background: ${selectedColourRgb}`" />
                </div>
                <div class="page-options">
                    <img class="delete-icon" @click="changeOption({clear: true})" :src="require('@/assets/images/deleteIcon.svg')"/>
                    <img class="delete-icon" @click="downloadImage()" :src="require('@/assets/images/downloadIcon.svg')"/>
                </div>
            </div>
            <div class="color-options-container">
                <div 
                    v-for="color in colors"  
                    :key="color.id"
                    :style="`background: ${color.rgb}`"
                    @click="changeOption({newColor: color.id})"
                    class="color-option">

                </div>
            </div>
            <div class="brush-options-container">
                <div 
                    v-for="line in lines"  
                    :key="line.id"
                    @click="changeOption({newBrush: line.id})"
                    class="brush-option"
                    :class="{ selected: selectedBrush === line.id}">
                        <div
                            class="line"
                            :style="`height: ${line.width}px`"
                        >
                        </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import touchPaintFile, {cleanUp, setOption, optionsData} from "../gameFiles/touchPaintIndex";
export default {
    name: 'touchPaintFile',
    data() {
        return {
            selectedColor: 0,
            selectedBrush: 0,
            image: '',
        }
    },
    computed: {
        colors() {
            return  optionsData?.colorsList.map(color => {
                return {
                    ...color,
                    rgb: `rgb(${color.red},${color.green},${color.blue})`,
                }
            });
        },
        lines() {
            return optionsData?.brushes;
        },
        selectedColourRgb() {
            return this.colors[this.selectedColor].rgb;
        },
    },
    methods: {
        changeOption(data) {
            if (data.newBrush !== undefined) this.selectedBrush = data.newBrush;
            if (data.newColor !== undefined) this.selectedColor = data.newColor;
            setOption(data);
        },
        createImage() {
            let canvas = document.getElementById('canvas');
            this.image = canvas.toDataURL();
            document.getElementById('show-image').src = this.image;
            const createEl = document.createElement('a');
            createEl.href = this.image;

            createEl.download = "touch-painting";

            createEl.click();
            createEl.remove();
        },
        downloadImage() {
            let canvas = document.getElementById('canvas');
            this.image = canvas.toDataURL();
            const createEl = document.createElement('a');
            createEl.href = this.image;

            createEl.download = "touch-painting";
            createEl.click();
            createEl.remove();
        }
    },
    mounted() {
        touchPaintFile();
    },
    beforeDestroy() {
        cleanUp();
    }
}
</script>

<style lang="scss" scoped>
    #show-image {
        height: 100%;
        width: 100%;
    }

    canvas {
      background: white;
    }

    #touch-container {
        display: flex;
        flex-direction: row;
        height: 100%;

    }

    .paint-options {
      background: lightblue;
      width: 200px;
      height: 100%;
    }

    .display-options {
        width: 100%;
        height: 35%;
        display: flex;
        justify-content: center;
    }

    .page-options {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .delete-icon {
        width: 50px;
        margin: 5px;
        border: 3px solid black;
        border-radius: 5px;
        padding: 5px;
    }

    .selected-color-container{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .selected-color {
        width: 80px;
        height: 80px;
        margin: 10px;
        border: 5px solid #72641c;
    }

    .color-options-container {
        width: 100%;
        height: 30%;
        display: flex;
        flex-wrap: wrap;
    }

    .color-option {
        height: 40px;
        width: 40px;
        margin: 5px;
    }

    .brush-options-container {
        width: 100%;
        height: 35%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .brush-option {
        padding: 5px;
        margin: 2px;
        width: 60%;
        display: flex;
        justify-content: center;
        border: 3px solid white;
        border-radius: 5px;
    }

    .line {
        background: black;
        width: 90%;
    }

    .selected {
        border: 3px solid red;
    }
</style>